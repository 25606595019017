import * as Sentry from '@sentry/react'

export const logErrorToSentry = (...args: unknown[]) => {
  let msg = ''
  for (const a of args) {
    if (a instanceof Error) {
      const e = a
      Sentry.setExtra('exceptionMessage', msg)
      Sentry.captureException(e)
      return
    } else if (typeof a !== 'object') {
      msg += a as string
    } else {
      try {
        msg += ' ' + JSON.stringify(a)
      } catch (e) {
        msg += ' ' + a
      }
    }
  }
  Sentry.captureMessage(msg)
}
