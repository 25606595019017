import {
  BaseQueryFn,
  FetchArgs,
  FetchBaseQueryError,
} from '@reduxjs/toolkit/query'
import { logout } from 'auth/hooks/useLogout'
import { authSlice } from 'auth/authSlice'

type BQuery = BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError>
export const baseQueryWithLogout =
  (baseQuery: BQuery): BQuery =>
  async (args, baseQueryApi, extraOptions) => {
    const result = await baseQuery(args, baseQueryApi, extraOptions)

    if (result.error && [403, 401].includes(result.error.status as number)) {
      logout()
      baseQueryApi.dispatch(authSlice.actions.logout())
    }

    return result
  }
