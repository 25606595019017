import { FC } from 'react'
import arrowIcon from './arrow.svg'

export type LinkItem = { text: string; link: string }
type LinksProps = {
  linksList: LinkItem[]
}
export const Links: FC<LinksProps> = ({ linksList }) => (
  <div className=" relative text-slate-700">
    <h5 className="text-sm">Maybe try these instead</h5>
    <img className="absolute right-[100%] top-2 mr-1" src={arrowIcon} />
    <div className="mt-2">
      {linksList.map(({ text, link }) => (
        <a className="mr-6 text-lg underline" key={link} href={link}>
          {text}
        </a>
      ))}
    </div>
  </div>
)
