import { type AccountRoutes } from 'account/src/app/App'
import { type AdminRoutes } from 'admin/src/app/App'
import { type TooterRoutes } from 'tooter/src/app/App'
import { type SettingsRoutes } from 'settings-app/src/types'
import { fillRoute } from '../fillRoute'
import { RouteProps } from '../types'

export type AppsRoutes =
  | AccountRoutes
  | AdminRoutes
  | TooterRoutes
  | SettingsRoutes

export const getRoute = <T = RouteProps>(route: AppsRoutes, props?: T) =>
  props ? fillRoute(route, props) : route
