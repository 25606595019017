import ReactDOM from 'react-dom/client'
import { App } from './app/App'
import './main.css'
import 'react-toastify/dist/ReactToastify.min.css'
import React from 'react'

ReactDOM.createRoot(document.getElementById('root')!).render(
  <React.StrictMode>
    <App />,
  </React.StrictMode>,
)
