import { useRef, useEffect } from 'react'
import { userSlice } from 'user/slices/userSlice'
import { useSelector } from 'react-redux'

type HookProps = {
  onChange: () => void
}

const useUserChange = ({ onChange }: HookProps) => {
  const currentUser = useSelector(userSlice.selectors.selectCurrentUser)
  const prevUser = useRef(currentUser)

  useEffect(() => {
    if (!currentUser || currentUser.id === prevUser.current?.id) return

    onChange()
    prevUser.current = currentUser
  }, [currentUser?.id])
}

export default useUserChange
