import { useSelector } from 'react-redux'

import { emojisApi } from '../../api'
import { emojisSlice } from '../../emojisSlice'

const useFetchEmojis = () => {
  const emojis = useSelector(emojisSlice.selectors.selectEmojis)

  emojisApi.useGetApiV1CustomEmojisSearchQuery(
    { shortcode: [''] },
    { skip: emojis.length > 0 },
  )
}

export default useFetchEmojis
