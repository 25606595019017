import { SENTRY_DNS } from 'constants/services'
import { BaseConfig } from './types'

const config: BaseConfig = {
  sentry: {
    dsn: SENTRY_DNS,
    release: 'tooter',
  },
}

export default config
