import { api, GetApiV1CustomEmojisSearchApiResponse } from 'openapi/api'
import { toast } from 'react-toastify'
import { getErrorMsg } from 'error-kit/helpers/getErrorMsg'
import { FetchBaseQueryError } from '@reduxjs/toolkit/query'

import { emojisSlice } from './emojisSlice'

export const emojisApi = api.enhanceEndpoints({
  endpoints: {
    getApiV1CustomEmojisSearch: {
      async onQueryStarted(_args, { dispatch, queryFulfilled }) {
        try {
          const emojis = await queryFulfilled

          dispatch(emojisSlice.actions.addList(emojis.data))
        } catch (e) {
          toast.error(
            `Failed to fetch emois ${getErrorMsg(e as FetchBaseQueryError)}`,
          )
        }
      },
      transformResponse: (emojis: GetApiV1CustomEmojisSearchApiResponse) => {
        return emojis.map((e) => ({
          ...e,
          name: `${e.shortcode}:`,
          emojiUrl: e.staticUrl,
        }))
      },
    },
  },
})
