import { waitFor } from 'helpers/waitFor'
import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { subscribeBefore } from 'redux-subscribe-action'

export const useWlsyToolbar = ({ isDev }: { isDev: boolean }) => {
  const dispatch = useDispatch()

  useEffect(() => {
    void (async () => {
      await waitFor(() => !!window.wellesley?.event)
      window.wellesley?.event?.on(dispatch)
      subscribeBefore((action) => window.wellesley?.event?.emit(action))
    })()
  }, [isDev, dispatch])
}
