import { RouteProps } from '../types'

export const fillRoute = (route: string, propsAndParams: RouteProps) => {
  const { props, params } = Object.keys(propsAndParams).reduce(
    (acc, key) => {
      if (route.includes(`:${key}`)) {
        acc.props[key] = propsAndParams[key]
      } else {
        acc.params.set(key, propsAndParams[key])
      }

      return acc
    },
    { props: {} as RouteProps, params: new URLSearchParams() },
  )
  const path = Object.keys(props)
    .reduce((acc, key) => {
      const regex = new RegExp(`/:${key}\\?`, 'g')
      acc = acc.replace(regex, `/${props[key]}`)
      return acc.replace(new RegExp(`:${key}`, 'g'), props[key])
    }, route)
    .replace(/\/:[^/]+?\?/g, '')

  return params.toString() ? `${path}?${params.toString()}` : path
}
