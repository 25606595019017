import { logger } from 'helpers/logger'
import { useNavigationType, NavigationType } from 'react-router-dom'

export const useRoute404Refresh = (error: string = '') => {
  const is404 = /No route matches/gi.test(error)
  try {
    const type = useNavigationType()

    if (is404 && type === ('PUSH' as NavigationType)) {
      location.reload()
    }
  } catch (e) {
    logger.warn('useRoute404Refresh: ', e)
  }
}
