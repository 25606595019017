export const waitFor = (
  check: () => boolean,
  limit = 10,
  name: string = 'waitFor',
) =>
  new Promise((resolve, reject) => {
    let count = 0

    const run = () => {
      if (check()) {
        resolve('OK')
      } else if (count > limit) {
        reject(`${name}: call limit reached`)
      } else {
        count = count + 1
        setTimeout(() => run(), 100)
      }
    }
    run()
  })
