import { values } from 'lodash'

export const routes = new Set<string>()
export const setRoutes = <T extends { [key in string]: { path?: string } }>(
  routesObj: T,
) => {
  routes.clear()
  values(routesObj)
    .map(({ path }) => path)
    .filter((v) => !!v)
    .forEach((v: string) => routes.add(v))
}
