import { authSlice } from 'auth/authSlice'
import { useSelector } from 'react-redux'
import { useGetApiV1NotificationsCountQuery } from '../../api'
import { NotificationStatus } from 'openapi'
import useUserChange from 'hooks/useUserChange'

type HookProps = {
  status?: NotificationStatus
}

const useGetNotificationsCount = ({ status }: HookProps) => {
  const isLogged = useSelector(authSlice.selectors.selectIsAuthenticated)

  const { refetch } = useGetApiV1NotificationsCountQuery(
    {
      ...(status && { status }),
    },
    { skip: !isLogged },
  )

  useUserChange({ onChange: () => void refetch() })
}

export default useGetNotificationsCount
