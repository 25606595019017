import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { SignupRequestStep } from 'openapi'

export type SignupFields = {
  email?: string
  hash?: string
  nextStep?: SignupRequestStep
}
export type ForgotPasswordFields = {
  login?: string
}

export type ForgotPasswordStep = 'request' | 'passCode' | 'confirm' | 'login'
export type SignupStep =
  | 'rules'
  | 'signup'
  | 'passCode'
  | 'captcha'
  | 'approval'

export type AuthState = {
  forgotPasswordStep: ForgotPasswordStep
  forgotPasswordFields: ForgotPasswordFields | null
  signupStep: SignupStep
  signupFields: SignupFields | null
  newEmail: string | null
  token: string | null
  isPending: boolean
  isAuthBlockVisible: boolean
  isAuthenticated: boolean
}
export const AUTH_COOKIE_NAME = 'auth_token'

const initialState: AuthState = {
  forgotPasswordStep: 'request',
  forgotPasswordFields: null,
  signupStep: 'rules',
  signupFields: null,
  token: null,
  newEmail: null,
  isAuthBlockVisible: true,
  isPending: false,
  isAuthenticated: false,
}

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    logout: () => initialState,
    setNewEmail: (state, action: PayloadAction<string | null>) => {
      state.newEmail = action.payload
    },
    setForgotPasswordStep: (
      state,
      action: PayloadAction<ForgotPasswordStep>,
    ) => {
      state.forgotPasswordStep = action.payload
    },
    updateForgotPasswordFields: (
      state,
      action: PayloadAction<ForgotPasswordFields | null>,
    ) => {
      state.forgotPasswordFields = {
        ...state.forgotPasswordFields,
        ...action.payload,
      }
    },
    resetForgotPasswordFields: (state) => {
      state.forgotPasswordFields = null
    },
    setSignupStep: (state, action: PayloadAction<SignupStep>) => {
      state.signupStep = action.payload
    },
    updateSignupFields: (state, action: PayloadAction<SignupFields | null>) => {
      state.signupFields = { ...state.signupFields, ...action.payload }
    },
    resetSignupFields: (state) => {
      state.signupFields = null
    },
    setPending: (state, action: PayloadAction<boolean>) => {
      state.isPending = action.payload
    },
    showAuthBlock: (state, action: PayloadAction<boolean>) => {
      state.isAuthBlockVisible = action.payload
    },
    login: (state, action: PayloadAction<string>) => {
      state.isAuthenticated = true
      state.token = action.payload
      state.isPending = false
    },
  },
  selectors: {
    selectToken: (state) => state.token,
    selectNewEmail: (state) => state.newEmail,
    selectForgotPasswordStep: (state) => state.forgotPasswordStep,
    selectForgotPasswordFields: (state) => state.forgotPasswordFields,
    selectSignupStep: (state) => state.signupStep,
    selectSignupFields: (state) => state.signupFields,
    selectPending: (state) => state.isPending,
    selectAuthBlockVisible: (state) => state.isAuthBlockVisible,
    selectIsAuthenticated: (state) => state.isAuthenticated,
  },
})
