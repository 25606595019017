import { useCallback, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { AUTH_COOKIE_NAME, authSlice } from '../../authSlice'
import { authCookieWatcher } from '../../helpers/authCookieWatcher'
import { api } from 'openapi/api'
import { userSlice } from 'user/slices/userSlice'

export const useAuthCookieWatcher = () => {
  const dispatch = useDispatch()

  const logout = useCallback(() => {
    dispatch(authSlice.actions.logout())
    dispatch(userSlice.actions.reset())
    dispatch(api.util.resetApiState())
  }, [dispatch])

  useEffect(() => {
    authCookieWatcher.run({
      cookieName: AUTH_COOKIE_NAME,
      onLogin: (authCookie) => {
        dispatch(authSlice.actions.login(authCookie))
        dispatch(api.util.invalidateTags(['Secure']))
      },
      onLogout: logout,
    })
  }, [logout, dispatch])
}
