import { logErrorToSentry } from 'sentry/logErrorToSentry'
import isServer from '../is/isServer'

export type LogLevel = 'debug' | 'info' | 'warn' | 'error'

const levels: LogLevel[] = ['debug', 'info', 'warn', 'error']

const shouldLog = (currentLevel: LogLevel, messageLevel: LogLevel): boolean => {
  const currentLevelIndex = levels.indexOf(currentLevel)
  const messageLevelIndex = levels.indexOf(messageLevel)
  return messageLevelIndex >= currentLevelIndex
}

const log = (
  level: LogLevel,
  currentLevel: LogLevel,
  message: string,
  ...args: unknown[]
): void => {
  if (shouldLog(currentLevel, level)) {
    console.log(`[${level}] ${message}`, ...args)
  }
}

export const createLogger = (
  initialLevel: LogLevel = (!isServer() &&
    (window?.localStorage.getItem('logger_lvl') as LogLevel)) ||
    'warn',
) => {
  let currentLevel = initialLevel

  return {
    debug: (message: string, ...args: unknown[]) =>
      log('debug', currentLevel, message, ...args),
    info: (message: string, ...args: unknown[]) =>
      log('info', currentLevel, message, ...args),
    warn: (message: string, ...args: unknown[]) =>
      log('warn', currentLevel, message, ...args),
    error: (message: string, ...args: unknown[]) => {
      logErrorToSentry(message, ...args)
      return log('error', currentLevel, message, ...args)
    },
    setLevel: (level: LogLevel) => {
      currentLevel = level
    },
    getLevel: () => currentLevel,
  }
}

export const logger = createLogger()
