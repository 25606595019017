import { useCallback } from 'react'
import { AUTH_COOKIE_NAME } from '../authSlice'
import { cookie } from 'helpers/cookie'

export const logout = () => {
  cookie.remove(AUTH_COOKIE_NAME)
}

export const useLogout = () => {
  const handleLogout = useCallback(logout, [])

  return { logout: handleLogout }
}
