import { isString } from 'lodash/fp'
import { SerializedError } from '@reduxjs/toolkit'
import { FetchBaseQueryError } from '@reduxjs/toolkit/query'
import { StatusCode, getErrorByStatusCode } from './getErrorByStatusCode'
import { ServerApiError } from '../../openapi'

export type ErrorT =
  | string
  | FetchBaseQueryError
  | SerializedError
  | { data: ServerApiError }

export const getErrorMsg = (error?: ErrorT) => {
  if (!error) return getErrorByStatusCode(500).description
  if (isString(error)) return error
  if ('data' in error && isString(error.data)) return error.data
  if ('data' in error && (error.data as ServerApiError)?.errorCode)
    return (error.data as ServerApiError)?.message
  if ('isUnhandledError' in error && !error.isUnhandledError) {
    const { status, data: localError } = (error as { error?: unknown })
      ?.error as {
      status?: StatusCode
      data?: ServerApiError | string
    }

    return (
      (isString(localError) ? localError : localError?.message) ||
      getErrorByStatusCode(status || 500).description
    )
  }
  if ('message' in error && error.message) return error.message
  if ('error' in error) return error.error
  if ('status' in error)
    return getErrorByStatusCode(error.status as StatusCode).description
}
