import { useEffect } from 'react'
import { usePutApiV1UsersTzMutation } from 'user/userApi'
import { userSlice } from 'user/slices/userSlice'
import { useSelector } from 'react-redux'
import { getStoredGeo, saveTimezoneToStore } from 'helpers/geo'
import dayjs from 'dayjs'
import isToday from 'dayjs/plugin/isToday'
import { useDispatch } from 'react-redux'
import { TimeZone, UserFull } from 'openapi'

dayjs.extend(isToday)

const useTimezoneUpdate = () => {
  const dispatch = useDispatch()

  const user = useSelector(userSlice.selectors.selectCurrentUser)
  const [updateUserTimezone] = usePutApiV1UsersTzMutation()

  const storedGeo = getStoredGeo()

  const updateUserTz = async (timezone?: TimeZone) => {
    const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone

    const ianaTimezone =
      (timezone && timezone.autoUpdate) || user?.timezone?.autoUpdate
        ? userTimezone
        : timezone?.ianaTimezone

    const updatedTZ = {
      ...user?.timezone,
      ...timezone,
      ianaTimezone,
    }

    const response = await updateUserTimezone({
      timeZone: updatedTZ,
      htmlContent: false,
    })

    const successResponse = (response as { data: UserFull })?.data

    if (successResponse) {
      dispatch(userSlice.actions.setUserNoHtml(successResponse))
    }

    saveTimezoneToStore(userTimezone)
  }

  useEffect(() => {
    const isOutdated = !storedGeo?.date || !dayjs(storedGeo.date).isToday()
    const canUpdate = user?.timezone?.autoUpdate && isOutdated

    canUpdate && void updateUserTz()
  }, [user?.timezone?.autoUpdate, storedGeo?.date])

  return updateUserTz
}

export default useTimezoneUpdate
