import { cookie } from 'helpers/cookie'
import noop from 'lodash/fp/noop'

type AuthCookieWatcherProps = {
  cookieName: string
  onLogin: (authCookie: string) => void
  onLogout: () => void
  onReset?: () => void
}

let isLogin: boolean | null = null
let prevAuthToken: string | undefined

const checkAuthCookie = ({
  cookieName,
  onLogin,
  onLogout,
  onReset = noop,
}: AuthCookieWatcherProps) => {
  const authToken = cookie.get(cookieName)
  const isInit = isLogin === null

  if (!isLogin && authToken) {
    isLogin = true
    onLogin(authToken)
  } else if (isLogin && !authToken) {
    isLogin = false
    onLogout()
  } else if (isInit && !authToken) {
    isLogin = false
    onReset()
  } else if (authToken && prevAuthToken && authToken !== prevAuthToken) {
    isLogin = true
    onLogin(authToken)
  }

  prevAuthToken = authToken
}

const setWasLogged = (newWasLogged: boolean | null) => (isLogin = newWasLogged)

const setPrevAuthToken = (newPrevAuthToken?: string) =>
  (prevAuthToken = newPrevAuthToken)

const run = (props: AuthCookieWatcherProps) => {
  const check = () => checkAuthCookie(props)
  const cancelId = setInterval(check, 500)

  check()

  return () => clearInterval(cancelId)
}

export const authCookieWatcher = { run, setWasLogged, setPrevAuthToken }
