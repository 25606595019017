import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { AccountWithUser, UserFull } from 'openapi'

type UserStateData =
  | (AccountWithUser & {
      userNoHtml?: UserFull
    })
  | null

export type userState = {
  data: UserStateData
  isPending: boolean
  isError: boolean
  isSuccess: boolean
  error?: unknown
}

const initialState: userState = {
  data: null,
  isError: false,
  isPending: false,
  isSuccess: false,
}

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    reset: (state) => {
      Object.assign(state, initialState)
    },
    setData: (state, { payload }: PayloadAction<AccountWithUser | null>) => {
      state.data = payload
    },
    updateData: (
      state,
      { payload }: PayloadAction<Partial<AccountWithUser> | null>,
    ) => {
      state.data && Object.assign(state.data, payload)
    },
    setSuccess: (state, { payload }: PayloadAction<boolean>) => {
      state.isSuccess = payload
    },
    setPending: (state, { payload }: PayloadAction<boolean>) => {
      state.isPending = payload
    },
    setIsError: (state, { payload }: PayloadAction<boolean>) => {
      state.isError = payload
    },
    setError: (state, { payload }: PayloadAction<unknown>) => {
      state.error = payload
    },
    updateUser: (state, { payload }: PayloadAction<UserFull>) => {
      if (state.data?.users) {
        state.data.users = state.data.users.map((u) =>
          payload.id === u.id ? payload : u,
        )
      }
    },
    setUserNoHtml: (state, action: PayloadAction<UserFull>) => {
      if (state.data) {
        state.data.userNoHtml = action.payload
      }
    },
  },
  selectors: {
    selectIsError: (state) => state.isError,
    selectError: (state) => state.error,
    selectPending: (state) => state.isPending,
    selectData: (state) => state.data,
    selectAccount: (state) => state.data?.account,
    selectUsers: (state) => state.data?.users,
    selectUserById: (state, userId: string) =>
      state.data?.users.find(({ id }) => id === userId),
    selectCurrentUser: (state) =>
      state.data?.users.find(({ id }) => id === state.data?.selectedUserId),
    selectSelectedUserId: (state) => state.data?.selectedUserId,
    selectSuccess: (state) => state.isSuccess,
    selectUserNoHtml: (state) => state.data?.userNoHtml,
    selectIsAdmin: (state) => state.data?.account.role === 'ADMIN',
  },
})
