import baseConfig from './base'
import { EnvType } from './types'
import { devConfig } from './dev'
import { testConfig } from './test'
import { prodConfig } from './prod'
export * from './types'

const getConfig = (currentEnv: EnvType) => {
  switch (currentEnv) {
    case 'dev':
      return devConfig
    case 'test':
      return testConfig
    case 'prod':
      return prodConfig
  }
}

const env = process.env.DOMAIN_ENV as EnvType
const config = (currentEnv: EnvType = env || 'dev') => ({
  ...baseConfig,
  ...getConfig(currentEnv),
})

export default config
