import * as React from 'react'
import * as TooltipPrimitive from '@radix-ui/react-tooltip'
import { cva, type VariantProps } from 'class-variance-authority'

import { cn } from '@ui/lib/utils'

const TooltipProvider = TooltipPrimitive.Provider

const Tooltip = TooltipPrimitive.Root

const TooltipTrigger = TooltipPrimitive.Trigger

const tooltipContentVariants = cva(
  'z-50 overflow-hidden shadow-md animate-in fade-in-0 zoom-in-95 data-[state=closed]:animate-out data-[state=closed]:fade-out-0 ' +
    'data-[state=closed]:zoom-out-95 data-[side=bottom]:slide-in-from-top-1 data-[side=left]:slide-in-from-right-2 ' +
    'data-[side=right]:slide-in-from-left-2 data-[side=top]:slide-in-from-bottom-2',
  {
    variants: {
      size: {
        default: 'rounded-md border text-sm px-3 py-1.5',
        small:
          'rounded-md border px-[11px] py-[3px] text-[12px] leading-[16px]',
      },
      theme: {
        default: 'bg-popover text-popover-foreground',
        dark: 'bg-slate-900_90 text-slate-50',
      },
      defaultVariants: {
        size: 'small',
        theme: 'dark',
      },
    },
  },
)

export type ContentProps = React.ComponentPropsWithoutRef<
  typeof TooltipPrimitive.Content
> &
  VariantProps<typeof tooltipContentVariants>

const TooltipContent = React.forwardRef<
  React.ElementRef<typeof TooltipPrimitive.Content>,
  ContentProps
>(({ className, sideOffset = 1, size, theme, ...props }, ref) => (
  <TooltipPrimitive.Content
    ref={ref}
    sideOffset={sideOffset}
    className={cn(tooltipContentVariants({ size, theme, className }))}
    {...props}
  />
))
TooltipContent.displayName = TooltipPrimitive.Content.displayName

type TooltipWrapperProps = VariantProps<typeof tooltipContentVariants> &
  React.PropsWithChildren<{
    tooltipText: string
    triggerClassname?: string
  }>

const TooltipWrapper: React.FC<TooltipWrapperProps> = ({
  children,
  tooltipText,
  triggerClassname,
  ...contentProps
}) => (
  <Tooltip>
    <TooltipTrigger className={triggerClassname}>{children}</TooltipTrigger>
    {tooltipText && (
      <TooltipContent {...contentProps}>{tooltipText}</TooltipContent>
    )}
  </Tooltip>
)

export {
  Tooltip,
  TooltipTrigger,
  TooltipContent,
  TooltipProvider,
  TooltipWrapper,
}
