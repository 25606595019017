import {
  BaseQueryFn,
  FetchArgs,
  FetchBaseQueryError,
  FetchBaseQueryMeta,
} from '@reduxjs/toolkit/query'
import { toast } from 'react-toastify'

export type BQuery = BaseQueryFn<
  string | FetchArgs,
  unknown,
  FetchBaseQueryError,
  object,
  FetchBaseQueryMeta
>

const maxRetry = 5

const createErrorCheck =
  (error?: FetchBaseQueryError) => (statuses: number[]) => {
    if (!error) return false
    return statuses.includes(error.status as number)
  }

const delay = (delayTime: number) =>
  new Promise((resolve) => setTimeout(resolve, delayTime * 1000))

export const baseQueryRetry =
  (baseQuery: BQuery): BQuery =>
  async (args, baseQueryApi, extraOptions) => {
    let attempt = 0

    const retry = async (): Promise<ReturnType<BQuery>> => {
      const response = await baseQuery(args, baseQueryApi, extraOptions)
      const error = response.error

      if (attempt >= maxRetry) {
        if (!navigator.onLine) toast.error('Offline')
        return response
      } else {
        if (attempt > 0) await delay(Math.pow(2, attempt))
        attempt++
      }

      const checkError = createErrorCheck(error)

      switch (true) {
        case baseQueryApi.type === 'mutation':
          return response

        case !navigator.onLine:
        case checkError([408, 404]):
        case error && error?.status === 'FETCH_ERROR':
        case error && (error.status as number) >= 500: {
          return await retry()
        }
        case response?.error?.status === 429: {
          const retryAfter =
            response.meta?.response?.headers?.get('Retry-After')
          await delay(+(retryAfter || '0'))

          return await retry()
        }

        default:
          return response
      }
    }

    return await retry()
  }
