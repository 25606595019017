import { BrowserOptions } from '@sentry/browser'
import * as Sentry from '@sentry/react'

import isServer from 'helpers/is/isServer'
import isUnitTesting from 'testing/helpers/isUnitTesting'
import { logger } from 'helpers/logger'
import { getRelease } from '../getRelease'

declare global {
  interface Window {
    build?: string
  }
}

const env = process.env.DOMAIN_ENV || 'test'

if (!isServer()) {
  window['build'] = process.env.BUILD_NUMBER
}

export const isEnabled = () =>
  (env === 'test' || env === 'prod') && !isUnitTesting()

export type SentryProps = Required<Pick<BrowserOptions, 'dsn'>> &
  Partial<BrowserOptions>

const defaultIntegrations = [
  Sentry.replayIntegration(),
  Sentry.browserApiErrorsIntegration(),
  Sentry.dedupeIntegration(),
]

export const init = ({
  dsn,
  release,
  integrations = defaultIntegrations,
  ...others
}: SentryProps) => {
  if (!isEnabled() || !dsn) return

  try {
    Sentry.init({
      dsn,
      integrations,
      replaysSessionSampleRate: 0.1,
      replaysOnErrorSampleRate: 1.0,
      release: getRelease(release),
      environment: env,
      maxValueLength: 1024,
      ...others,
    })
  } catch (err) {
    logger.error('Sentry init error: ', err)
    return
  }
}

export type SentryUser = { email: string; username: string; userId: string }
export const setUser = (userProfile: SentryUser | null) => {
  if (isEnabled() && userProfile) {
    const { email, username, userId } = userProfile
    Sentry.setUser({ email, username, id: userId })
  }
}
