import { toast } from 'react-toastify'
import { useEffect } from 'react'
import { usePutApiV1UsersLocationMutation } from 'user/userApi'
import { userSlice } from 'user/slices/userSlice'
import { useSelector } from 'react-redux'
import { getLocation, getStoredGeo, saveGeoToStore } from 'helpers/geo'
import dayjs from 'dayjs'
import isToday from 'dayjs/plugin/isToday'
import { Geo, UserFull, Location } from 'openapi'
import { useDispatch } from 'react-redux'

dayjs.extend(isToday)

type GetLocationProps = {
  position?: GeolocationPosition
  location?: Location
}

const useLocationUpdate = () => {
  const dispatch = useDispatch()

  const user = useSelector(userSlice.selectors.selectCurrentUser)
  const [updateUserLocation] = usePutApiV1UsersLocationMutation()

  const storedGeo = getStoredGeo()

  const updateUser = async (geo: Geo, location?: Location) => {
    const response = await updateUserLocation({
      location: {
        ...user?.location,
        ...location,
        geo,
      },
      htmlContent: false,
    })

    const successResponse = (response as { data: UserFull })?.data

    if (successResponse) {
      dispatch(userSlice.actions.setUserNoHtml(successResponse))
    }
  }

  const updateLocation = ({ position, location }: GetLocationProps) => {
    const geo = {
      ...user?.location?.geo,
      ...(position?.coords && {
        latitude: position.coords.latitude,
        longitude: position.coords.longitude,
        accuracy: position.coords.accuracy,
      }),
    }

    saveGeoToStore(geo)
    void updateUser(geo, location)
  }

  const getGeo = (location?: Location) => {
    getLocation({
      onSuccess: (position) => updateLocation({ position, location }),
      onError: (e) => toast.error(`Update location error: ${e.message}`),
    })
  }

  useEffect(() => {
    const isOutdated = !storedGeo?.date || !dayjs(storedGeo.date).isToday()
    const canUpdate = user?.location?.autoUpdate && isOutdated

    canUpdate && getGeo()
  }, [user?.location?.autoUpdate, storedGeo?.date])

  return [getGeo, updateLocation] as const
}

export default useLocationUpdate
