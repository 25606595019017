import { Geo } from 'openapi'
import { toast } from 'react-toastify'

type GetGeo = {
  onSuccess: (position: GeolocationPosition) => void
  onError: (err: GeolocationPositionError) => void
}

type StoredGeo = {
  tz: string
  geo: Geo
  date: Date
} | null

const storageKey = `wlsy_geo`

export const isSupported = () => 'geolocation' in navigator

export const getLocation = ({ onSuccess, onError }: GetGeo) => {
  if (!isSupported()) {
    toast.error('Geolocation is not supported in your browser')
    return null
  }

  return navigator.geolocation.getCurrentPosition(onSuccess, onError)
}

export const getStoredGeo = () => {
  try {
    const storedData = window.localStorage.getItem(storageKey)
    const storedGeo = storedData ? (JSON.parse(storedData) as StoredGeo) : null

    return storedGeo
  } catch (e) {
    toast.error('Can not get cached geo from localstorage')
    return null
  }
}

export const saveGeoToStore = (geo: Geo) => {
  const existingData = getStoredGeo()

  window.localStorage.setItem(
    storageKey,
    JSON.stringify({
      ...existingData,
      geo,
      date: new Date(),
    } as StoredGeo),
  )
}

export const saveTimezoneToStore = (tz: string) => {
  const existingData = getStoredGeo()

  window.localStorage.setItem(
    storageKey,
    JSON.stringify({
      ...existingData,
      tz,
      date: new Date(),
    } as StoredGeo),
  )
}
