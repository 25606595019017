import { Emoji } from 'openapi'
import { rootReducer } from 'store'
import { PayloadAction, createSlice } from '@reduxjs/toolkit'

export type CustomEmoji = Emoji & { name?: string; emojiUrl?: string }

export type EmojisState = {
  list: CustomEmoji[]
}

const initialState: EmojisState = {
  list: [],
}

export const emojisSlice = createSlice({
  name: 'emojis',
  initialState,
  reducers: {
    addEmoji: (state, action: PayloadAction<Emoji>) => {
      state.list.push(action.payload)
    },
    addList: (state, action: PayloadAction<Emoji[]>) => {
      state.list = action.payload
    },
  },
  selectors: {
    selectEmojis: (state) => state.list,
  },
}).injectInto(rootReducer)
