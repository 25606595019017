import { PayloadAction, createSelector, createSlice } from '@reduxjs/toolkit'
import { Notification, NotificationStatus } from 'openapi'
import { rootReducer } from 'store'
import { NotificationItem, SetNotificationsPayload } from './types'

export type NotificationsSliceState = {
  data: {
    userId?: string
    list: NotificationItem[] | null
  }
  count: {
    total?: number
    unread?: number
  }
  isPending: boolean
  error?: unknown
}

type MarkNotificationProps = {
  id: string
  status: NotificationStatus
}

//todo: removed mocked edge cases when verified

// export const mockNotifications = [
//   {
//     id: 'nt_01jeb1me1kfk5ajrmzk3bg5knz',
//     userId: 'us_01j70s2hrbfb7aqpbb2yvb9hkk',
//     type: 'FOLLOW_REQUEST',
//     status: 'UNREAD',
//     fromUserId: 'us_01j947mfkmfk7tfebk0x9ebx42',
//     meta: {
//       actionsAvailable: [
//         {
//           action: '/api/v1/users/follow/ihor/accept',
//           text: 'Accept',
//         },
//         {
//           action: '/api/v1/users/follow/ihor/reject',
//           text: 'Reject',
//         },
//       ],
//     },
//     createdAt: '2024-12-05T09:15:21.025400Z',
//     fromUser: {
//       id: 'us_01j947mfkmfk7tfebk0x9ebx42',
//       username: 'Iryna_one',
//       domain: 'server1.welsley.social',
//       // domain: '',
//       displayName: 'Iryna_one DISPLAY NAME long 123 22132123',
//       uri: 'https://server1.wellesley.social/users/ihor',
//       createdAt: '2024-10-01T14:27:03.924871Z',
//       state: 'REGULAR',
//     },
//   },
//   {
//     id: 'nt_01je3f2zxgfym9etbxyz60gd1f',
//     userId: 'us_01j70s2hrbfb7aqpbb2yvb9hkk',
//     type: 'COMMENT',
//     status: 'READ',
//     activityId: 'pt_01je3f2zkbfvwvr1h6e79pq6tz',
//     fromUserId: 'us_01j65qm3zrebbvmzhd196d91c9',
//     meta: {},
//     createdAt: '2024-12-02T10:36:34.166709Z',
//     updatedAt: '2024-12-05T09:15:21.701034Z',
//     fromUser: {
//       id: 'us_01j947mfkmfk7tfebk0x9ebx42',
//       username: 'Iryna_one',
//       domain: 'server1.welsley.social',
//       // domain: '',
//       displayName: 'Iryna_one DISPLAY NAME long 123 22132123',
//       uri: 'https://server1.wellesley.social/users/ihor',
//       createdAt: '2024-10-01T14:27:03.924871Z',
//       state: 'REGULAR',
//     },
//     post: {
//       id: 'pt_01je3f2zkbfvwvr1h6e79pq6tz',
//       uri: 'https://server1.wellesley.social/posts/pt_01je3f2zkbfvwvr1h6e79pq6tz',
//       text: '<p>KU</p>',
//       path: ['pt_01jc5qsytafawah58zqzagtr2b', 'pt_01jdhkhqttfwrsnqxkqwezy3w3'],
//       privacy: 'FOLLOW_POST',
//       protocol: 'TOOT',
//       tags: [],
//       mentions: [],
//       stats: {
//         likes: 0,
//         reposts: 0,
//         comments: 0,
//       },
//       settings: {
//         commentsEnabled: true,
//         sensitive: false,
//       },
//       createdAt: '2024-12-02T10:36:33.771048Z',
//       updatedAt: '2024-12-02T10:36:33.771048Z',
//       author: {
//         id: 'us_01j65qm3zrebbvmzhd196d91c9',
//         username: 'admin',
//         domain: '',
//         displayName: 'admin',
//         uri: 'https://server1.wellesley.social/users/admin',
//         createdAt: '2024-08-25T21:38:38.200354Z',
//         state: 'REGULAR',
//       },
//       attachments: [
//         {
//           uploadId: 'up_01je95s6x3e8gsgmejn5n250mb',
//           userId: 'us_01j65qm3zrebbvmzhd196d91c9',
//           uploadType: 'video',
//           meta: {
//             blurhash: 'VeJRBoo#IoM{xu~Xt7xuE1xus;RPt7ofNGt8bHNGt7f6',
//             name: 'Nepal_1920x1080.mp4',
//           },
//           size: 9111571,
//           files: [
//             {
//               uri: 'https://server1.wellesley.social/media/up_01je95s6x3e8gsgmejn5n250mb/fl_01je95s6x3e8gsgmejn5n250ma.mp4',
//               fileId: 'fl_01je95s6x3e8gsgmejn5n250ma',
//               extension: 'mp4',
//               original: true,
//               meta: {
//                 duration: 20.54,
//                 width: 1920,
//                 height: 1080,
//                 codec: 'h264',
//               },
//               size: 9111571,
//               createdAt: '2024-12-04T15:49:22.979721590Z',
//               updatedAt: '2024-12-04T15:49:26.341938118Z',
//             },
//           ],
//           tags: ['$post'],
//           cached: false,
//           remote: false,
//           createdAt: '2024-12-04T15:49:22.979749Z',
//           updatedAt: '2024-12-04T15:49:41.197306Z',
//         },
//       ],
//       emojis: [],
//       replyTo: 'pt_01jdhkhqttfwrsnqxkqwezy3w3',
//       conversationId: 'pt_01jc5qsytafawah58zqzagtr2b',
//     },
//   },
//   {
//     id: 'nt_01jenj4tt7ecj864trdbmk4dnm',
//     userId: 'us_01j70s3zzzf2ya4w8ge9h9nhaz',
//     type: 'FOLLOW_REQUEST',
//     status: 'READ',
//     fromUserId: 'us_01j7gpwapffrpsatxfs5w2wr0s',
//     meta: {
//       actionsAvailable: [
//         {
//           action: '/api/v1/users/follow/Iryna2@server2.wellesley.social/accept',
//           text: 'Accept',
//         },
//         {
//           action: '/api/v1/users/follow/Iryna2@server2.wellesley.social/reject',
//           text: 'Reject',
//         },
//       ],
//     },
//     createdAt: '2024-12-09T11:16:19.912815Z',
//     updatedAt: '2024-12-09T14:58:23.781331Z',
//     fromUser: {
//       id: 'us_01j7gpwapffrpsatxfs5w2wr0s',
//       username: 'Iryna2',
//       domain: 'server2.wellesley.social',
//       displayName: 'Iryna2_____________',
//       uri: 'https://server2.wellesley.social/users/Iryna2',
//       avatar: {
//         uploadId: 'up_01jenj4rprf75bmz7vjht1q2ff',
//         userId: 'us_01j7gpwapffrpsatxfs5w2wr0s',
//         uploadType: 'image',
//         meta: {},
//         size: 0,
//         files: [
//           {
//             uri: 'https://server2.wellesley.social/media/up_01j801a1s1f6gbsww2tvzeexyf/fl_01j801a1s1f6gbsww2tvzeexye.jpeg',
//             fileId: 'fl_01jenj4rprf75bmz7vjht1q2fe',
//             extension: 'jpeg',
//             original: true,
//             meta: {
//               duration: 0.0,
//             },
//             size: 0,
//             createdAt: '2024-12-09T11:16:17.752072773Z',
//             updatedAt: '2024-12-09T11:16:17.752072980Z',
//           },
//         ],
//         tags: ['$avatar'],
//         cached: false,
//         remote: true,
//         createdAt: '2024-12-09T11:16:17.752099Z',
//         updatedAt: '2024-12-09T11:16:17.752099Z',
//       },
//       createdAt: '2024-09-11T14:12:59.215942Z',
//       state: 'REGULAR',
//     },
//   },
// ]

const initialState: NotificationsSliceState = {
  data: {
    userId: '',
    list: [],
  },
  count: {},
  isPending: false,
}

const selectState = (state: NotificationsSliceState) => state
const selectError = (state: NotificationsSliceState) => state.error
const selectIsPending = (state: NotificationsSliceState) => state.isPending
const selectTotalCount = (state: NotificationsSliceState) => state.count?.total
const selectUnreadCount = (state: NotificationsSliceState) =>
  state.count?.unread

const selectNotificationsOwner = (state: NotificationsSliceState) =>
  state.data.userId

const selectNotifications = createSelector(
  selectState,
  (state) => state.data.list,
)
const selectUnreadNotifications = createSelector(selectNotifications, (list) =>
  list?.filter((n) => n.status === 'UNREAD'),
)
const selectLastNotification = createSelector(selectNotifications, (n) =>
  n?.at(-1),
)

export const notificationsSlice = createSlice({
  name: 'notifications',
  initialState,
  reducers: {
    setPending: (state, action: PayloadAction<boolean>) => {
      state.isPending = action.payload
    },
    setError: (state, action: PayloadAction<unknown>) => {
      state.error = action.payload
    },
    setNotifications: (
      state,
      action: PayloadAction<SetNotificationsPayload>,
    ) => {
      const userId = action.payload.userId
      const notifications = action.payload.list

      state.data = {
        userId,
        list: [
          ...(state.data.list || []),
          ...notifications.filter(
            (n) => !state.data.list?.some((item) => item.id === n.id),
          ),
        ],
      }
    },
    addNotification: (state, action: PayloadAction<Notification>) => {
      state.count.unread = (state.count.unread || 0) + 1

      if (state.data.list) {
        state.data.list = [action.payload, ...(state.data.list || [])]
      }
    },
    markNotification: (state, action: PayloadAction<MarkNotificationProps>) => {
      if (!state.data.list) return

      state.data.list = state.data.list.map((n) =>
        n.id === action.payload.id
          ? { ...n, status: action.payload.status }
          : n,
      )
    },
    updateNotification: (state, action: PayloadAction<NotificationItem>) => {
      if (!state.data.list) return

      const updatedNotification = action.payload

      state.data.list = state.data.list?.map((n) =>
        n.id === updatedNotification.id ? { ...n, ...updatedNotification } : n,
      )
    },
    setTotalCount: (state, action: PayloadAction<number>) => {
      state.count.total = action.payload
    },
    setUnreadCount: (state, action: PayloadAction<number>) => {
      state.count.unread = action.payload
    },
    readAll: (state) => {
      if (state.data.list) {
        state.data.list = state.data.list?.map((n) => ({
          ...n,
          status: 'READ',
        }))
      }
    },
    resetState: () => initialState,
    undoNotitication: (state, action: PayloadAction<string>) => {
      const id = action.payload
      const isUnread =
        state.data.list?.find((n) => n.id === id)?.status === 'UNREAD'

      if (isUnread && !!state.count.unread) {
        state.count.unread -= 1
      }

      state.data.list = state.data.list?.filter((n) => n.id !== id) || []
    },
  },
  selectors: {
    selectError,
    selectIsPending,
    selectTotalCount,
    selectUnreadCount,
    selectNotifications,
    selectUnreadNotifications,
    selectLastNotification,
    selectNotificationsOwner,
  },
}).injectInto(rootReducer)
