import { authSlice } from 'auth/authSlice'
import { useEffect, useRef } from 'react'
import { useSelector } from 'react-redux'
import { getRoute } from 'router/getRoute'

const useWatchLogout = (logoutRedirectUrl = getRoute('/')) => {
  const isLogged = useSelector(authSlice.selectors.selectIsAuthenticated)
  const wasLogged = useRef<boolean>()

  useEffect(() => {
    if (isLogged && !wasLogged.current) {
      wasLogged.current = true
    }
  }, [isLogged, wasLogged])

  useEffect(() => {
    const canRedirect = wasLogged.current && !isLogged

    if (canRedirect) {
      location.href = logoutRedirectUrl
    }
  }, [isLogged, wasLogged.current])
}

export default useWatchLogout
