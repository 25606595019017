import { LinkItem } from '../ErrorPage/Links'

export type StatusCode =
  | 400
  | 401
  | 403
  | 404
  | 440
  | 460
  | 498
  | 500
  | 'user404'
  | 'post404'
  | 'page404'

export type ErrorBody = {
  title?: string
  description?: string
  links?: LinkItem[]
}

const errors: {
  [key in StatusCode]: ErrorBody
} = {
  400: {
    title: '',
    description: 'You have no permission ...',
  },
  401: {
    title: 'Unauthorized',
    description:
      'Your access is restricted. Please ensure you have the correct login details',
  },
  404: {
    title: 'Oh no, bad luck!',
    description:
      'Looks like the page you were looking for has been removed. Just in case, please make sure to check your spelling.',
  },
  user404: {
    title: 'We could not find the user you were looking for',
    description: 'Check the username and try one more time',
    links: [
      { text: 'To the Wellsley account', link: '/account' },
      { text: 'To account settings', link: '/account/settings' },
    ],
  },
  page404: {
    title: 'We could not find the page you were looking for',
    description: 'Check the address and try one more time',
  },
  post404: {
    title: 'We could not find the post you were looking for',
    description: 'Check the address and try one more time',
  },
  403: {
    title: '',
    description: 'Your session has been expired. Please re-login again.',
  },
  440: {
    title: '',
    description:
      'You have reached maximum allowed number of concurrent sessions.',
  },
  460: {
    title: '',
    description: 'Please re-login again.',
  },
  498: {
    title: 'Expired',
    description: 'The link was expired or invalid. Please try again.',
  },
  500: {
    title: 'Oops, something went wrong!',
    description:
      'We are already on it. Please try again later or contact us at <a href="mailto:support@wave.video">support@wave.video</a>',
  },
}

export const isErrorBody = (error: unknown): error is ErrorBody => {
  const { title, description, links } = (error as ErrorBody) || {}
  return !!(title || description || links)
}

export const isStatusError = (code: unknown): code is StatusCode =>
  Object.keys(errors).some((k) => k == code)

export const getErrorByStatusCode = (statusCode: StatusCode) => {
  return errors[statusCode] || errors[500]
}
